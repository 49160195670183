<p class="text-center text-2xl md:text-4xl text-gfx-primary dark:text-gfx-dark-primary font-bold mb-2">
	Group Fitness X Pricing
</p>
<div class="flex justify-center">
	<div class="grid grid-cols-2 gap-4 max-w-4xl">
		<div class="border-2 border-gfx-primary rounded-lg p-0 shadow-xl h-fit">
			<mat-card class="rounded-lg">
				<mat-card-content>
					<div class="grid gap-4">
						<div class="flex-row justify-center">
							<div class="text-2xl text-gfx-primary dark:text-gfx-dark-primary font-bold flex justify-center">
								GFX Basic
							</div>
							<div class="text-sm text-zinc-500 dark:text-zinc-400 flex justify-center">Access to free features</div>
						</div>
						<div class="flex-row justify-center">
							<div class="flex-row justify-center gap-2">
								<div class="font-bold text-xl text-center text-gfx-primary dark:text-gfx-dark-primary">Free</div>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="border-2 border-gfx-primary rounded-lg p-0 shadow-xl">
			<mat-card class="rounded-lg">
				<mat-card-content>
					<div class="grid gap-4">
						<div class="flex flex-col justify-center">
							<div class="text-2xl text-gfx-primary dark:text-gfx-dark-primary font-bold flex justify-center">
								GFX Pro Subscription
							</div>
							<div class="text-sm text-zinc-500 dark:text-zinc-400 flex justify-center">
								Full access to GFX features
							</div>
						</div>
						<div class="flex flex-col md:flex-row justify-evenly">
							<div class="flex justify-center">
								<div class="flex flex-col gap-2">
									<div class="font-bold text-xl text-center text-gfx-primary dark:text-gfx-dark-primary">$5</div>
									<div class="text-sm text-center text-zinc-500 dark:text-zinc-400">per month</div>
									<div>
										@if (isAuthenticated$()) {
											<button
												mat-flat-button
												class="gfx-button-primary"
												[disabled]="hasActiveSubscription$()"
												(click)="onChoosePlan('monthly')"
												[hidden]="hasActiveSubscription$()">
												Choose Plan
											</button>
										}
									</div>
								</div>
							</div>

							<div class="text-center text-zinc-500 dark:text-zinc-400">or</div>
							<div class="flex justify-center">
								<div class="flex flex-col gap-2">
									<div class="font-bold text-xl text-center text-gfx-primary dark:text-gfx-dark-primary">$50</div>
									<div class="text-sm text-center text-zinc-500 dark:text-zinc-400">per year</div>
									<div>
										@if (isAuthenticated$()) {
											<button
												mat-flat-button
												class="gfx-button-primary"
												[disabled]="hasActiveSubscription$()"
												(click)="onChoosePlan('yearly')"
												[hidden]="hasActiveSubscription$()">
												Choose Plan
											</button>
										}
									</div>
								</div>
							</div>
						</div>

						<div class="text-center text-gfx-primary dark:text-gfx-dark-primary mt-4">
							{{ hasActiveSubscription$() ? 'You are currently subscribed' : '14-day trial' }}
						</div>
						@if (isAuthenticated$() && hasActiveTrial$() && trialEndDate$()) {
							<div class="mx-auto text-xs text-wrap text-center text-zinc-500 dark:text-zinc-400">
								You won't be billed until your trial ends on {{ trialEndDate$() }}.
							</div>
						}
						<div class="flex justify-center">
							@if (!isAuthenticated$()) {
								<button mat-flat-button class="gfx-button-primary" [routerLink]="['/login']">Join Now</button>
							}
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="col-start-1 row-start-2">
			<div class="flex justify-center border-2 border-gfx-primary rounded-lg shadow-lg">
				<mat-card class="w-full">
					<mat-card-content>
						<div class="grid grid-cols-1">
							<div class="text-xl text-center text-gfx-primary dark:text-gfx-dark-primary font-bold mt-4">Basic</div>
							<mat-divider class="my-2"></mat-divider>
							<div class="grid grid-cols-5 gap-2 my-4">
								<div class="col-start-1 col-span-1 row-start-1 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-1">Free 14-day Trial</div>
								<div class="col-start-1 col-span-1 row-start-2 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-2">Personal Instructor Profile</div>
								<div class="col-start-1 col-span-1 row-start-3 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-3">Community Feed</div>
								<div class="col-start-1 col-span-1 row-start-4 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-4">View and Share Public Playlists</div>
								<div class="col-start-1 col-span-1 row-start-5 justify-self-end">
									<mat-icon class="text-red-500 align-middle">remove_circle_outline</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-5">Search Release Catalog</div>
								<div class="col-start-1 col-span-1 row-start-6 justify-self-end">
									<mat-icon class="text-red-500 align-middle">remove_circle_outline</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-6">Search Playlists</div>
								<div class="col-start-1 col-span-1 row-start-7 justify-self-end">
									<mat-icon class="text-red-500 align-middle">remove_circle_outline</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-7">Create Community Playlists</div>
								<div class="col-start-1 col-span-1 row-start-8 justify-self-end">
									<mat-icon class="text-red-500 align-middle">remove_circle_outline</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-8">Side-by-side Choreography Notes</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div class="row-start-2 col-start-2">
			<div class="flex justify-center border-2 border-gfx-primary rounded-lg shadow-lg">
				<mat-card class="w-full">
					<mat-card-content>
						<div class="grid grid-cols-1">
							<div class="text-xl text-center text-gfx-primary dark:text-gfx-dark-primary font-bold mt-4">
								Pro <mat-icon>loyalty</mat-icon>
							</div>
							<mat-divider class="my-2"></mat-divider>
							<div class="grid grid-cols-5 gap-2 my-4">
								<div class="col-start-1 col-span-1 row-start-1 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-1">Free 14-day Trial</div>
								<div class="col-start-1 col-span-1 row-start-2 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-2">Personal Instructor Profile</div>
								<div class="col-start-1 col-span-1 row-start-3 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-3">Community Feed</div>
								<div class="col-start-1 col-span-1 row-start-4 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-4">View and Share Public Playlists</div>
								<div class="col-start-1 col-span-1 row-start-5 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-5">Search Release Catalog</div>
								<div class="col-start-1 col-span-1 row-start-6 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-6">Search Playlists</div>
								<div class="col-start-1 col-span-1 row-start-7 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-7">Create Community Playlists</div>
								<div class="col-start-1 col-span-1 row-start-8 justify-self-end">
									<mat-icon class="text-green-500 align-middle">check</mat-icon>
								</div>
								<div class="col-start-2 col-span-4 row-start-8">Side-by-side Choreography Notes</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</div>
